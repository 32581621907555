import platformClient from "purecloud-platform-client-v2";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { IContextUserProps } from "context-user";

import PureCloud from "../services/PureCloud";
import { useAppAlertContext } from "./AlertContext";
import { useNavigate } from "react-router-dom";

interface IFuncComponentsProps {
  children: React.ReactNode;
}

const UserContext = createContext({} as IContextUserProps);

export const useAppUserContext = () => useContext(UserContext);

export const AppUserProvider: React.FC<IFuncComponentsProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const { showAlert } = useAppAlertContext();

  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState<platformClient.Models.UserMe>();

  const userMemo = useMemo(() => user, [user]);

  const validUser = useCallback(
    (groupId: string) => {
      console.log(groupId, user);
      if (user) {
        const result = user.groups?.filter((g) => g.id === groupId);
        if (result && result.length > 0) return;
      }

      console.error("You don't have permission to access.");
      if (loaded) navigate("/");
    },
    [user, loaded]
  );

  useEffect(() => {
    PureCloud.authenticate()
      .then(() => PureCloud.getUsersMe())
      .then((user) => {
        setUser(user);
      })
      .catch((err) => {
        console.error(err);
        showAlert({
          severity: "error",
          message: "Erro ao autenticar o usuario.",
        });
      })
      .finally(() => setLoaded(true));
  }, []);

  return (
    <UserContext.Provider value={{ loaded, user: userMemo, validUser }}>
      {children}
    </UserContext.Provider>
  );
};
