import { createTheme } from "@mui/material";

export const DefaultTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#3B476B",
      light: "#000",
      dark: "#193F70",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#6684BE",
      light: "#000",
      dark: "#000",
      contrastText: "#FFF",
    },
    background: {
      default: "#F7F6F3",
      paper: "#FFF",
    },
  },
});
