import { useEffect, useState } from "react";
import { Box, Button, useMediaQuery } from "@mui/material";

import { Router4MeLogo, Solve4MeLogo } from "../../shared/assets";
import { useAppThemeContext } from "../../shared/context";

const pureCloudEnv = process.env.REACT_APP_GENESYS_ENVIRONMENT;

export const WelcomePage = () => {
  const { theme } = useAppThemeContext();
  const [logoHeight, setLogoHeight] = useState<number>(12);

  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  function handleLogin() {
    const url = `login.${pureCloudEnv}`;
    window.location.replace(url);
  }

  useEffect(() => {
    setLogoHeight(10);
  }, [mdDown]);

  useEffect(() => {
    setLogoHeight(8);
  }, [smDown]);

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      sx={{
        backgroundImage: "url(background.svg)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left top",
        backgroundSize: "cover", //"auto",
      }}
    >
      <Box
        flex={1}
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box>
          <Solve4MeLogo height={theme.spacing(logoHeight)} />
        </Box>
        <Box flex={1} />
        <Box>
          <Router4MeLogo height={theme.spacing(3 * logoHeight)} />
        </Box>
        <Box marginTop={theme.spacing(2)}>
          <Button variant="contained" color="secondary" onClick={handleLogin}>
            Login
          </Button>
        </Box>
        <Box flex={2} />
      </Box>
      <Box flex={1} />
    </Box>
  );
};
