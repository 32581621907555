import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { App } from "./App";
import {
  AppAlertProvider,
  AppThemeProvider,
  AppUserProvider,
} from "./shared/context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <AppThemeProvider>
      <BrowserRouter>
        <AppAlertProvider>
          <AppUserProvider>
            <App />
          </AppUserProvider>
        </AppAlertProvider>
      </BrowserRouter>
    </AppThemeProvider>
  </React.StrictMode>
);
