import { Alert, Slide, useMediaQuery } from "@mui/material";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { IAlertData, IContextAlertProps } from "context-alert";

import { useAppThemeContext } from "./ThemeContext";

interface IFuncComponentsProps {
  children: React.ReactNode;
}

const _defaultTimeout = 1000;

const AlertContext = createContext({} as IContextAlertProps);

export const useAppAlertContext = () => useContext(AlertContext);

export const AppAlertProvider: React.FC<IFuncComponentsProps> = ({
  children,
}) => {
  const { theme } = useAppThemeContext();
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState<IAlertData>();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const showAlert = useCallback(
    (alert: IAlertData, interval?: number) => {
      setAlert(alert);
      setShow(true);

      setTimeout(() => setShow(false), interval || _defaultTimeout);
    },
    [setShow]
  );

  const isShowing = useMemo(() => show, [show]);

  return (
    <AlertContext.Provider value={{ isShowing, showAlert }}>
      <>
        <Slide direction="left" in={show}>
          <Alert
            sx={{
              position: "fixed",
              width: smDown ? "50vw" : "20vw",
              top: theme.spacing(10),
              right: "0",
            }}
            severity={alert?.severity}
          >
            {alert?.message}
          </Alert>
        </Slide>

        {children}
      </>
    </AlertContext.Provider>
  );
};
