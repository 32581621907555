import { Routes, Route, Navigate } from "react-router-dom";

import { ListsPage, ManualPage, WelcomePage } from "../pages";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<WelcomePage />} />
      <Route path="/manual" element={<ManualPage />} />
      <Route path="/list" element={<ListsPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
