import { useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import { useEffect, useMemo, useState } from "react";
import { Models } from "purecloud-platform-client-v2";
import {
  Box,
  Button,
  CircularProgress,
  Grow,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { SelectBox } from "../../../shared/components";
import PureCloud from "../../../shared/services/PureCloud";
import {
  useAppAlertContext,
  useAppThemeContext,
  useAppUserContext,
} from "../../../shared/context";
import Router4Me from "../../../shared/services/Router4Me";

const hsmOrigins = (process.env.REACT_APP_MANUAL_ALLOWED_ORIGINS || "").split(
  ","
);
const defaultOrigin = hsmOrigins.length === 1 ? hsmOrigins[0] : "";

export const HSMView = () => {
  const { user } = useAppUserContext();
  const { theme } = useAppThemeContext();
  const { showAlert } = useAppAlertContext();

  let navigate = useNavigate();

  const [from, setFrom] = useState<string>(defaultOrigin);

  const [loading, setLoading] = useState(false);

  const [to, setTo] = useState<string>("");
  const [library, setLibrary] = useState<string>("");
  const [campaign, setCampaign] = useState<string>("");
  const [message, setMessage] = useState<string>();
  const [parameters, setParameters] = useState<any>();

  const [showMessage, setShowMessage] = useState<string>();

  const [libraries, setLibraries] = useState<Array<Models.Library>>([]);
  const [campaigns, setCampaigns] = useState<Array<Models.Response>>([]);

  const isValidTo = useMemo(() => !to || /(\d{12}|\d{13})/.test(to), [to]);

  function removeFilterAnnotation(message: string) {
    return message.replace(/(\[\[filter:.*\]\] ?)/g, "");
  }

  useEffect(() => {
    if (to && to !== "")
      PureCloud.getCannedResponseLibraries()
        .then((res): void => setLibraries(res || []))
        .catch((err) => {
          console.error(err);
          showAlert({
            severity: "error",
            message: "Erro ao coletar as informações",
          });
        });
  }, [to, showAlert]);

  useEffect(() => {
    if (library && library !== "")
      PureCloud.getCannedResponses(library)
        .then((res): void => {
          if (!res) return setCampaigns([]);

          const campaigns = res
            .filter((c) => c.messagingTemplate?.whatsApp)
            .filter((c) => c.texts[0].content.includes("[[filter:"))
            .filter((c) =>
              c.texts[0].content
                .match(/filter:([\w\d\s\W\D\S\n]*?)(?=\]\])/)?.[1]
                ?.split(",")
                ?.includes(from)
            );

          setCampaigns(campaigns);
        })
        .catch((err) => {
          console.error(err);
          showAlert({
            severity: "error",
            message: "Erro ao coletar as informações",
          });
        });
  }, [library, from, showAlert]);

  useEffect(() => {
    const selectedCampaign = campaigns.filter((c) => c.name === campaign)[0];
    if (selectedCampaign) {
      setMessage(selectedCampaign.texts[0].content);
    } else setMessage(undefined);
  }, [campaigns, campaign]);

  useEffect(() => {
    if (!message) {
      setParameters(undefined);
      return setShowMessage(undefined);
    }

    const regex = /(?<={\{)(.*?)(?=\}\})/g;

    const params = message.match(regex);

    let tmp: any = {};

    setShowMessage(removeFilterAnnotation(message));

    if (!params) return setParameters(undefined);

    for (const p of params) tmp[p as keyof any] = "";

    setParameters(tmp);
  }, [message]);

  useEffect(() => {
    if (!message) {
      setParameters(undefined);
      return setShowMessage(undefined);
    }

    let tmp = removeFilterAnnotation(message);

    if (parameters)
      for (const k of Object.keys(parameters)) {
        if (!parameters[k] || parameters[k] === "") continue;
        tmp = tmp?.replace(`{{${k}}}`, parameters[k]);
      }

    setShowMessage(tmp);
  }, [parameters]);

  function handlerFrom(text: string) {
    setTo("");
    setLibrary("");
    setCampaign("");
    setMessage("");
    setFrom(text);
  }

  function handlerTo(text: string) {
    setLibrary("");
    setCampaign("");
    setMessage("");
    setTo(text);
  }

  function handlerLibrary(text: string) {
    setCampaign("");
    setMessage("");
    setLibrary(text);
  }

  function handlerParameter(key: string, text: string) {
    const tmp = { ...parameters };
    tmp[key as keyof any] = text;
    setParameters(tmp);
  }

  function handlerCampaign(text: string) {
    setMessage("");
    setCampaign(text);
  }

  function handlerSubmit() {
    if (!user || !user.email) return navigate("/");

    /*const values = parameters
      ? Object.keys(parameters).map((k) => parameters[k as keyof any])
      : [];*/

    setLoading(true);

    Router4Me.sendHSM(from, to, user.email, campaign, parameters)
      .then(() => {
        showAlert({ severity: "success", message: "HSM enviado com sucesso." });

        setFrom(defaultOrigin);
        setTo("");
        setLibrary("");
        setCampaign("");
        setMessage("");

        setLibraries([]);
        setCampaigns([]);
      })
      .catch((err) => {
        console.error(err);
        showAlert({ severity: "error", message: "Falha ao enviar o HSM." });
      })
      .finally(() => setLoading(false));
  }

  return (
    <Grow in timeout={500}>
      <div>
        <Box display="flex" flexDirection="column" gap={theme.spacing(1)}>
          <SelectBox
            allowEmpty
            label="Origem *"
            value={from}
            options={hsmOrigins}
            helper="Selecione o numero de origem."
            onSelected={handlerFrom}
          />

          <TextField
            disabled={!from}
            error={!isValidTo}
            inputProps={{ maxLength: 13 }}
            fullWidth
            label="Telefone *"
            value={to}
            variant="outlined"
            helperText="Digite o numero de destino"
            onChange={(e) => handlerTo(e.target.value)}
          />

          <SelectBox
            allowEmpty
            disabled={!to || !isValidTo}
            label="Biblioteca *"
            value={library}
            options={libraries.map((l) => {
              return { key: l.id || "", value: l.name };
            })}
            helper="Selecione a biblioteca de campanhas."
            onSelected={handlerLibrary}
          />

          <SelectBox
            allowEmpty
            disabled={!library}
            label="Campanha *"
            value={campaign}
            options={campaigns.map((c) => c.name || "")}
            helper="Selecione a campanha."
            onSelected={handlerCampaign}
          />

          {parameters &&
            Object.keys(parameters).map((k: string, i: number) => (
              <TextField
                key={`field-parameter-${i}`}
                fullWidth
                label={k}
                value={parameters[k]}
                variant="outlined"
                helperText={`Digite o parametro "${k}"`}
                onChange={(e) => handlerParameter(k, e.target.value)}
              />
            ))}

          {showMessage && (
            <Paper sx={{ p: theme.spacing(3), textAlig: "justify" }}>
              <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
                {showMessage}
              </Typography>
            </Paper>
          )}

          <Box display="flex" sx={{ mt: theme.spacing(2) }}>
            <Box flex={1} />
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                disabled={!message}
                sx={{
                  paddingLeft: theme.spacing(5),
                  paddingRight: theme.spacing(5),
                }}
                variant="contained"
                endIcon={<SendIcon />}
                onClick={handlerSubmit}
              >
                Enviar
              </Button>
            )}
            <Box flex={1} />
          </Box>
        </Box>
      </div>
    </Grow>
  );
};
