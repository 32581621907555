import { Grow, Typography } from "@mui/material";

export const SMSView = () => {
  return (
    <Grow in timeout={500}>
      <div>
        <Typography>SMS</Typography>
      </div>
    </Grow>
  );
};
