const router4meURL = process.env.REACT_APP_ROUTER4ME_HOST;
const router4meToken = process.env.REACT_APP_ROUTER4ME_TOKEN;

export default class Router4Me {
  static sendHSM(
    from: string,
    to: string,
    agentId: string,
    template: string,
    values?: any
  ) {
    return new Promise<void>((resolve, reject) => {
      const headers: HeadersInit = new Headers({
        "Content-Type": "application/json",
      });
      if (router4meToken)
        headers.append("Authorization", `Bearer ${router4meToken}`);

      const body = {
        to,
        template: { name: template, values },
        attributes: { agentId },
      };

      if (values) body.template.values = values;

      fetch(`${router4meURL}/core/${from}/api/v1/providers/outbound`, {
        headers,
        method: "POST",
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.status >= 200 && res.status < 300) return resolve();
          return reject("Invalid status Code");
        })
        .catch(reject);
    });
  }
}
