import { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";

import { HSMView } from "./views/HSM";
import { SMSView } from "./views/SMS";
import { BaseLayout } from "../../shared/layouts";
import { SelectBox } from "../../shared/components";
import { useAppThemeContext, useAppUserContext } from "../../shared/context";

const groupId = process.env.REACT_APP_MANUAL_GROUP || "";
const manualChannels = (process.env.REACT_APP_MANUAL_CHANNELS || "").split(",");

export const ManualPage = () => {
  const { theme } = useAppThemeContext();
  const { validUser } = useAppUserContext();

  const [channel, setChannel] = useState<string>(
    manualChannels.length === 1 ? manualChannels[0] : ""
  );

  const [bodyPadding, setBodyPadding] = useState<string>();

  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => validUser(groupId), [validUser]);

  useEffect(
    () =>
      setBodyPadding(
        smDown
          ? theme.spacing(0)
          : mdDown
          ? theme.spacing(5)
          : theme.spacing(35)
      ),
    [theme, smDown, mdDown]
  );

  const renderOpts = () => {
    switch (channel) {
      case "HSM":
        return <HSMView />;
      case "SMS":
        return <SMSView />;
      default:
        return <Box height={theme.spacing(50)}></Box>;
    }
  };

  return (
    <BaseLayout title="Envio Manual">
      <Box
        display="flex"
        flexDirection="column"
        rowGap={theme.spacing(2)}
        marginLeft={bodyPadding}
        marginRight={bodyPadding}
      >
        <SelectBox
          label="Envio *"
          options={manualChannels}
          helper="Selecione o tipo de envio."
          value={channel}
          onSelected={setChannel}
        />
        {renderOpts()}
      </Box>
    </BaseLayout>
  );
};
