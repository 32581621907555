import { ISelectBoxProps, TOption } from "component-select-box";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

export const SelectBox: React.FC<ISelectBoxProps> = ({
  label,
  options,
  value,
  disabled,
  helper,
  allowEmpty,
  onSelected,
}) => {
  return (
    <FormControl fullWidth size="medium" disabled={disabled || false}>
      <InputLabel id={`${label}-select-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-select-label`}
        id={`${label}-select`}
        value={value}
        label={label}
        onChange={(e) => onSelected && onSelected(e.target.value)}
      >
        {allowEmpty && (
          <MenuItem disabled value="">
            <em>None</em>
          </MenuItem>
        )}
        {options.map((o: TOption, i: number) => {
          const isString = typeof o === "string";

          return (
            <MenuItem
              key={`${label}-select-item-${i}`}
              value={isString ? o : o.key}
            >
              {isString ? o : o.value}
            </MenuItem>
          );
        })}
      </Select>

      {helper && <FormHelperText>{helper}</FormHelperText>}
    </FormControl>
  );
};
