import { ILayoutBaseProps } from "layout-base";

import { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { Solve4MeLogo } from "../assets";
import { useAppThemeContext } from "../context";
import { useAppUserContext } from "../context/UserContext";
import { Navigate } from "react-router-dom";

export const BaseLayout: React.FC<ILayoutBaseProps> = ({ title, children }) => {
  const { loaded, user } = useAppUserContext();
  const { theme } = useAppThemeContext();

  const [appBarHeight, setAppBarHeight] = useState<number>(7);

  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    setAppBarHeight(smDown ? 5 : mdDown ? 6 : 7);
  }, [smDown, mdDown, theme]);

  return (
    <Box
      height="100vh"
      width="100vw"
      maxHeight="100vh"
      maxWidth="100vw"
      display="flex"
      flexDirection="column"
      overflow="auto"
    >
      <AppBar
        position="fixed"
        sx={{
          display: "flex",
          flexDirection: "row",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Box margin="auto" marginLeft={theme.spacing(4)}>
          <Solve4MeLogo fill="#FFF" height={theme.spacing(appBarHeight)} />
        </Box>

        <Box flexGrow={1} />

        {!smDown && (
          <Typography
            whiteSpace="nowrap"
            textOverflow="ellipses"
            variant="body1"
            fontWeight="bold"
            flex={1}
            align="right"
            margin="auto"
            marginRight={theme.spacing(4)}
          >
            {title}
          </Typography>
        )}
      </AppBar>

      <Box
        flexGrow={1}
        marginTop={theme.spacing(appBarHeight + 5)}
        marginLeft={theme.spacing(5)}
        marginRight={theme.spacing(5)}
      >
        {loaded && user ? (
          children
        ) : !loaded && !user ? (
          <Skeleton
            variant="rectangular"
            sx={{ flexGrow: 1, height: "100%" }}
          />
        ) : (
          <Navigate to="/" />
        )}
      </Box>

      <Box width="100%" marginTop={theme.spacing(2)}>
        <Typography width="100%" align="center" color="grey">
          © Copyright 2024 Solve4ME
        </Typography>
      </Box>
    </Box>
  );
};
