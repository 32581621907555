import {
  UsersApi,
  AuthData,
  ResponseManagementApi,
  Models,
} from "purecloud-platform-client-v2";

const platformClient = window.require("platformClient");

const genesysEnvironment = process.env.REACT_APP_GENESYS_ENVIRONMENT;
const genesysImplicitToken = process.env.REACT_APP_GENESYS_IMPLICIT_TOKEN;

const client = platformClient.ApiClient.instance;

const usersApi: UsersApi = new platformClient.UsersApi();
const responseManagementApi: ResponseManagementApi =
  new platformClient.ResponseManagementApi();

export default class PureCloud {
  static authenticate() {
    console.info("[PureCloud] authenticate()");

    return new Promise<Promise<AuthData>>((resolve, reject) => {
      //this.client.setPersistSettings(true, "S4ME");

      if (!genesysEnvironment)
        return reject("Not configured genesys environment");
      if (!genesysImplicitToken)
        return reject("Not configured genesys implicit token");

      client.setEnvironment(genesysEnvironment);

      const { origin, protocol, host, pathname } = window.location;
      const redirectUrl = (origin || `${protocol}//${host}`) + pathname;

      resolve(
        client.loginImplicitGrant(genesysImplicitToken, redirectUrl, {
          state: "ok",
        })
      );
    });
  }

  static getUsersMe() {
    console.info("[PureCloud] getUsersMe()");

    let opts = {
      expand: ["groups"],
    };

    //GET /api/v2/users/me
    return usersApi.getUsersMe(opts);
  }

  static getCannedResponseLibraries() {
    //GET
    let opts = {
      pageSize: 250, // Number | Page size
      pageNumber: 1, // Number | Page number
      messagingTemplateFilter: "whatsapp",
    };

    return new Promise<Models.Library[] | undefined>((resolve, reject) =>
      responseManagementApi
        .getResponsemanagementLibraries(opts)
        .then((res) => resolve(res.entities))
        .catch(reject)
    );
  }

  static getCannedResponses(library: string) {
    let opts = {
      pageSize: 250, // Number | Page size
      pageNumber: 1, // Number | Page number
    };

    //GET
    return new Promise<Models.Response[] | undefined>((resolve, reject) =>
      responseManagementApi
        .getResponsemanagementResponses(library, opts)
        .then((res) => resolve(res.entities))
        .catch(reject)
    );
  }
  /*public static getIntegrations(): Promise<WhatsappIntegrations | null> {
    let opts = {
      pageSize: 100, // Number | Page size
      pageNumber: 1, // Number | Page number
    };
    return new Promise(async (resolve, reject) => {
      const responses = new this.platformClient.ConversationsApi();
      responses
        .getConversationsMessagingIntegrationsWhatsapp(opts)
        .then((data: WhatsappIntegrations) => {
          console.info(
            "[getIntegrations] successfully to get whatsapp integrations in Purecloud"
          );
          resolve(data);
        })
        .catch((err: SDKError) => {
          const { status, error, statusText } = err;
          console.error(
            `[getIntegrations] Error on get whatsapp integrations in PureCloud: ${status} - ${statusText}`
          );
          console.error(error);
          reject(null);
        });
    });
  }*/
}
