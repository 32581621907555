import { Box } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { createContext, useContext } from "react";

import { IContextThemeProps } from "context-theme";

import { DefaultTheme } from "../themes";

interface IFuncComponentsProps {
  children: React.ReactNode;
}

const ThemeContext = createContext({} as IContextThemeProps);

export const useAppThemeContext = () => useContext(ThemeContext);

export const AppThemeProvider: React.FC<IFuncComponentsProps> = ({
  children,
}) => {
  return (
    <ThemeContext.Provider value={{ theme: DefaultTheme }}>
      <ThemeProvider theme={DefaultTheme}>
        <Box
          width="100vw"
          height="100vh"
          bgcolor={DefaultTheme.palette.background.default}
        >
          {children}
        </Box>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
